@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 600px) {
  /* Adjust the max-width value according to your needs */
  /* You may need to experiment with different values based on your layout */

  /* Assuming the search bar has a class like 'search-bar' */
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-clear-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
}

@font-face {
  font-family: "OffBit-dot-bold";
  src: url("/src/assets/font/OffBit-DotBold.woff") format('truetype')
}

@font-face {
  font-family: "OffBit-dot-normal";
  src: url("/src/assets/font/OffBit-Dot.woff2") format('truetype')
}

@font-face {
  font-family: 'Whytte';
  src: url('/src/assets/font/whytte.woff2')
}

@font-face {
  font-family: 'BaseNeue-Variable';
  src: url('./assets/font/BaseNeue-Variable.woff2')
}

@font-face {
  font-family: 'Yapari-Variable';
  src: url('./assets/font/Yapari-Variable.woff2')
}

@font-face {
  font-family: 'Megazoid';
  src: url('./assets/font/Megazoid.woff')
}

@font-face {
  font-family: 'TTExtraBold';
  src: url("./assets/font/TT-Norms-Pro-ExtraBold.otf") format("opentype");
}
@font-face {
  font-family: 'TTNormal';
  src: url("./assets/font/TT-Norms-Pro-Normal.otf") format("opentype");
}



.bg {
  position: fixed;
  z-index: 100;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 150%;
  height: 150%;
  background: url('./assets/noise-01.webp')repeat;
  background-repeat: repeat;
  opacity: 1;
  visibility: visible;
  pointer-events: none;
  display: block;
  mix-blend-mode: luminosity;
}




.bor {
  border: 2px solid red;
}


.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.textSrc {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.imgSrc:hover .textSrc {
  opacity: 1;
}
